@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* Call to Action */

/*Features Card */

.containerfeature {
  width: "20vw";
  background-color: "#FFFFFF";
  box-shadow: "5px 10px red";
  font-family: "Poppins";
}

.textcontainer {
  width: "20vw";
  padding: "10px";
}

.featurescardh1 {
  font-size: "16px";
  color: "#37207b";
}

.featurescardp {
  font-size: "16px";
  color: "#404040";
}

/* Founder Box */

.founderContainer {
  padding-top: 40px;
  padding-bottom: 40px;
  /* width: 60%; */
}

.founderrow {
  width: 90%;
  padding: 20px;
}

.founderspam {
  color: #d13669;
  font-weight: bold;
}

.founderCardQuote {
  padding-bottom: 2vh;
}

.founderquote {
  font-size: 30px;
}

.foundertext{
  font-size: 20px;
}

.spacer {
  padding: 0vh;
}

.big {
  height: 100px;
}

/* Image Banner */

.banner {
  width: 100%;
  display: flex;
  justify-content: space-between;
  max-height: 30vh;
  object-fit: cover;
  margin-top: 10px;
  margin-left: -20px;
}

.banner img {
  border-radius: 50%;
  padding: 10px;
}

/* Footer */

.footerContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  background-color: rgba(55, 32, 123, 1);
}

.footerlogo {
  padding: 10px;
}

.footerlinks a {
  color: rgba(177, 179, 182, 1);
  padding: 10px;
  text-decoration: none;
  font-size: 12px;
}

.footerlinks a:hover {
  color: #d13669;
}

.footerlinks {
  padding: 10px;
}

/* Privacy and Terms */

.legalcontainer {
  padding: 20px;
  font-family: "Poppins";
}

.legalh1 {
  color: #d13669;
}
